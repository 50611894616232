import { Component, Input } from '@angular/core';
import { ILeasingQuoteCreditRatingDto } from '@abcfinlab/api/global';
import { ILeasingQuoteObligationsDTO } from 'apps/shell/src/app/models/ILeasingQuoteObligationsDTO.interface';

@Component({
    selector: 'l7-quote-summary-card-obligations',
    templateUrl: './quote-summary-card-obligations.component.html',
    styleUrls: ['./quote-summary-card-obligations.component.scss'],
})
export class QuoteSummaryCardObligationsComponent {

    @Input() obligations: Array<ILeasingQuoteObligationsDTO>;
    @Input() quoteCreditRating: ILeasingQuoteCreditRatingDto;

    /**
     * Check if the quote has *obligations* to show in the summary list
     */
    public get quoteHasObligations(): boolean {
        return !!this.obligations && this.obligations.length > 0;
    }

    /**
     * Check if the quote is capable of having obligations by the given CreditCheck status
     * - only quotes with status *ApprovedWithConditions* & *ApprovedWithConditionsFulfilled*
     *   can have obligations
     */
    public get quoteSupportsObligations(): boolean {
        return this.quoteCreditRating === ILeasingQuoteCreditRatingDto.ApprovedWithConditions
            || this.quoteCreditRating === ILeasingQuoteCreditRatingDto.ApprovedWithConditionsFulfilled;
    }

}
