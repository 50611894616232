import { Pipe, PipeTransform } from '@angular/core';
import { LeasingQuoteDTO } from 'apps/shell/src/app/models/LeasingQuoteDTO.interface';
import { LeasingQuoteOverviewQuoteDTO } from 'apps/shell/src/app/models/LeasingQuoteOverviewQuoteDTO.interface';
import { LeasingQuoteOverviewVersionDTO } from 'apps/shell/src/app/models/LeasingQuoteOverviewVersionDTO.interface';
import { ILeasingQuoteOverviewQuoteDto, ILeasingQuoteOverviewVersionDto, IRemoteQuoteDto } from '@abcfinlab/api/global';

@Pipe({
    name: 'quoteName',
})
export class QuoteNamePipe implements PipeTransform {

    private readonly _prefix: string = '';
    private readonly _length: number = 8;

    transform(quote: LeasingQuoteDTO | LeasingQuoteOverviewVersionDTO | LeasingQuoteOverviewQuoteDTO | ILeasingQuoteOverviewVersionDto | ILeasingQuoteOverviewQuoteDto): string {
        if (!quote.quote_name) {
            // has no quote_name field (old BE implementation
            const quoteName: string = String(quote.quote_number).padStart(this._length, '0');
            if ('quote_version' in quote) {
                // no version field in DTO (LeasingQuoteOverviewQuoteDTO)
                const quoteVersion: string = String(quote.quote_version).padStart(2, '0');
                return `${this._prefix}${quoteName}-${quoteVersion}`;
            } else {
                // with version field in DTO
                return `${this._prefix}${quoteName}`;
            }
        } else {
            // has new quote_name field
            return quote.quote_name;
        }
    }

}
