/* apps/shell/src/app/private/pages/route-container-quotes/page-quote-detail-view/quote-detail-view.component.scss */
:host .page-container {
  position: relative;
  width: unset;
}
:host .cam-check-container {
  position: absolute;
  top: 16%;
  padding: 10px;
}
:host .info-popup-container {
  padding: 8px;
  font-weight: 700;
}
:host .info-popup-container__body {
  color: #b1162c;
}
:host .location ::ng-deep .address-card {
  border: solid 1px #c9d0d4;
}
:host .location ::ng-deep l7-address-card {
  width: 100%;
}
:host .location ::ng-deep l7-address-card mat-card-content p:first-child {
  font-weight: bold;
}
:host .vendor .section-header a {
  margin: 20px 0;
  text-align: right;
  text-decoration: underline;
}
:host .vendor .vendor-cards {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #eff2f3;
  border: solid 1px #c9d0d4;
}
:host .vendor .vendor-cards l7-address-card:first-child {
  min-width: 55%;
}
:host .vendor .vendor-cards l7-address-card {
  min-width: 45%;
}
:host .slb ::ng-deep .mat-card {
  min-height: 66px;
}
:host l7-address-card ::ng-deep mat-card {
  min-height: 150px;
  color: #83929b;
  background-color: #ffffff;
  font-size: 14px;
  border: none;
  box-shadow: none !important;
  padding: 0;
}
:host l7-address-card ::ng-deep .mat-card-header-text {
  margin: 0;
  width: 100%;
}
:host l7-address-card ::ng-deep mat-card-content {
  flex-direction: row;
  display: flex;
  position: relative;
  top: -12px;
  margin-bottom: 0;
}
:host l7-address-card ::ng-deep .left {
  width: 5px;
  display: flex;
  flex-direction: column;
}
:host l7-address-card ::ng-deep .center {
  width: 90%;
}
:host l7-address-card ::ng-deep .mat-mdc-card-actions {
  min-height: 20px;
}
:host l7-address-card ::ng-deep .actions {
  padding: 10px;
}
:host l7-address-card ::ng-deep .actions a {
  text-decoration: underline;
}
:host l7-quote-summary-card ::ng-deep .fee .section-header {
  padding-top: 15px;
}
:host l7-quote-summary-card ::ng-deep l7-calculator-fee-form hr {
  display: none;
}
:host l7-quote-summary-card ::ng-deep l7-calculator-fee-form l7-input {
  margin-bottom: 10px;
}
:host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container {
  grid-column: span 2;
}
:host l7-quote-summary-card ::ng-deep .form.form-insurance .mat-form-field-suffix {
  width: 64px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-weight: 400 !important;
  font-size: 1.4em;
}
:host l7-quote-summary-card ::ng-deep .form.form-insurance .mat-form-field {
  box-shadow: 0 0 5px 0 #d9eaef;
  border: 1px solid #9fbac8;
  width: 100%;
}
:host l7-quote-summary-card ::ng-deep .form.form-insurance {
  padding: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
@media screen and (min-width: 1024px) {
  :host l7-quote-summary-card ::ng-deep .form.form-insurance {
    padding: unset;
  }
}
:host l7-quote-summary-card ::ng-deep .form.form-insurance .checkbox-label {
  font-size: 17px;
  font-weight: bold;
}
:host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container {
  display: flex;
  flex-direction: column;
}
:host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container.hire-purchase {
  grid-column: span 2;
}
:host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container.hire-purchase .left l7-input {
  padding-left: 10px;
}
:host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container.hire-purchase .left l7-input #input_hire_purchase_terms {
  width: 100px;
}
:host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container .left,
:host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container .center,
:host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container .right {
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}
@media screen and (min-width: 1024px) {
  :host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container .left {
    width: 14% !important;
  }
  :host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container .left.select-insurance {
    width: 49% !important;
  }
}
@media screen and (min-width: 1024px) {
  :host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container .center {
    width: 33% !important;
  }
}
@media screen and (min-width: 1024px) {
  :host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container .right,
  :host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container .total-claim,
  :host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container .total-claim-insurance {
    width: 49% !important;
  }
}
@media screen and (min-width: 1024px) {
  :host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container {
    flex-direction: row;
    justify-content: space-between;
  }
}
:host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container .slide-toggle {
  padding: 18px 0;
}
@media screen and (min-width: 1024px) {
  :host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container .slide-toggle {
    width: 48%;
  }
}
@media screen and (min-width: 1024px) {
  :host l7-quote-summary-card ::ng-deep .form.form-insurance .input-container .input {
    width: 48%;
  }
}
:host l7-quote-summary-card ::ng-deep .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #f2f4f5;
}
:host l7-quote-summary-card ::ng-deep .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #4296b1;
}
:host l7-quote-summary-card ::ng-deep mat-slide-toggle {
  width: 100%;
}
:host l7-quote-summary-card ::ng-deep mat-slide-toggle label {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
:host l7-quote-summary-card ::ng-deep mat-slide-toggle label .mat-slide-toggle-bar {
  margin-right: 20px;
}
:host l7-quote-summary-card ::ng-deep .quote-summary-card.mat-card {
  box-shadow: none;
}
:host header {
  position: relative;
}
:host header .cam-check-container {
  position: absolute;
  right: 10px;
  top: 60%;
  padding: 10px;
}
:host fieldset {
  width: 100%;
  display: block;
}
:host footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
:host .field-description {
  margin: 20px 0;
  font-weight: 500;
}
:host .fieldset-object.fieldset-hire-purchase {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 20px 0;
}
/*# sourceMappingURL=quote-detail-view.component.css.map */
