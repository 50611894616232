import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuoteDocumentTableAction } from '../../retailer-quote-details/RetailerQuoteDetailsView';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

export interface IQuoteDocumentTableActionData {
    actionType: QuoteDocumentTableAction;
    isDraft: boolean;
    lesseeName: string;
}

@Component({
    selector: 'l7-retailer-quote-document-action-view',
    templateUrl: './RetailerQuoteDocumentAction.html',
    styleUrls: ['./RetailerQuoteDocumentAction.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerQuoteDocumentActionView {

    private readonly _dialogRef: MatDialogRef<RetailerQuoteDocumentActionView>;
    protected sendDraftForm: UntypedFormGroup;
    protected readonly QuoteDocumentTableAction = QuoteDocumentTableAction;

    public constructor(dialogRef: MatDialogRef<RetailerQuoteDocumentActionView>, @Inject(MAT_DIALOG_DATA) public dialogData: IQuoteDocumentTableActionData, private readonly _fb: UntypedFormBuilder) {
        this._dialogRef = dialogRef;
        this.sendDraftForm = this._fb.group({
            email: [{
                value: null,
                disabled: false,
            }, [Validators.required, Validators.email]],
        });
    }

    public onClose(submit: boolean): void {
        if (submit && this.dialogData.actionType === this.QuoteDocumentTableAction.SEND && this.sendDraftForm.invalid) {
            this.sendDraftForm.controls.email.markAsTouched();
            return;
        }
        if (!submit) {
            this._dialogRef.close();
            return;
        }
        if (this.dialogData.actionType === this.QuoteDocumentTableAction.SEND) {
            this._dialogRef.close({ email: this.sendDraftForm.controls.email.getRawValue() });
        } else {
            this._dialogRef.close(submit);
        }
    }

}
