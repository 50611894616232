import { AfterViewInit, Component, OnInit } from '@angular/core';
import { RetailerQuotesListPresenter } from '../retailer-quotes-list/retailer-quotes-list.presenter';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IRetailerQuoteVersionListMenu } from '../../Components/retailer-quote-versions-cards/retailer-quote-versions-cards.component';
import { BreakpointService } from '../../../../../libs/layout/src/Services/BreakpointService';

@UntilDestroy()
@Component({
    selector: 'l7-retailer-quotes-list',
    templateUrl: './retailer-quotes-list.component.html',
    styleUrls: ['./retailer-quotes-list.component.css'],
    providers: [RetailerQuotesListPresenter],
})
export class RetailerQuotesListComponent implements OnInit, AfterViewInit {

    private readonly _columns: Array<string>;
    public activeView: 'CARD' | 'TABLE' = 'TABLE';
    public quoteVersionActions: Array<IRetailerQuoteVersionListMenu> = [
        { action: 'downloadQuote', label: 'quote.retailers.quotesList.versions.menu.downloadQuote', icon: 'File_Download' },
        { action: 'contractOffer', label: 'quote.retailers.quotesList.versions.menu.contractOffer', icon: 'External_Link_2' },
        { action: 'createNewVersion', label: 'quote.retailers.quotesList.versions.menu.createNewVersion', icon: 'Repeat' },
    ];

    public isMobile: boolean;

    constructor(
        private readonly _presenter: RetailerQuotesListPresenter,
        private readonly _breakpointService: BreakpointService) {}

    public get presenter(): RetailerQuotesListPresenter {
        return this._presenter;
    }

    public get columns(): Array<string> {
        return this._columns;
    }

    public ngOnInit(): void {
        this._breakpointService.isDesktopViewport$.pipe(
            untilDestroyed(this),
        ).subscribe((isDesktopMode) => {
            if (isDesktopMode) {
                this.isMobile = false;
                this.activeView = 'TABLE';
            } else {
                this.isMobile = true;
                this.activeView = 'CARD';
            }
        });
    }

    public ngAfterViewInit(): void {
        this.presenter.initialize();
    }

    public changeViewType(type: 'CARD' | 'TABLE'): void {
        this.activeView = type;
    }

}
