// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BeneficialOwnerEditDialogViewPresenter } from './BeneficialOwnerEditDialogViewPresenter';

// #endregion

/**
 * The `BeneficialOwnerEditDialogView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-beneficial-owner-edit-dialog-view',
    templateUrl: './BeneficialOwnerEditDialogView.html',
    styleUrls: ['./BeneficialOwnerEditDialogView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        BeneficialOwnerEditDialogViewPresenter,
    ],
})
export class BeneficialOwnerEditDialogView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: BeneficialOwnerEditDialogViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `BeneficialOwnerEditDialogView` class.
     *
     * @public
     */
    public constructor(presenter: BeneficialOwnerEditDialogViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `BeneficialOwnerEditDialogView`
     *
     * @public
     * @readonly
     */
    public get presenter(): BeneficialOwnerEditDialogViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
