import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ISearchRetailerQuoteResultDto } from '@abcfinlab/api/global';

export interface IRetailerQuoteVersionListMenu {
    action: string;
    label: string;
    icon: string;
}
export interface IRetailerQuoteVersionListMenuEvent {
    action: string;
    quoteId: string;
}

@Component({
    selector: 'l7-retailer-quote-versions-cards',
    templateUrl: './retailer-quote-versions-cards.component.html',
    styleUrls: ['./retailer-quote-versions-cards.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerQuoteVersionsCardsComponent {

    @Input() versions: Array<ISearchRetailerQuoteResultDto>;
    @Input() menuActions: Array<IRetailerQuoteVersionListMenu>;
    @Output() goToVersionDetailView: EventEmitter<ISearchRetailerQuoteResultDto> = new EventEmitter<ISearchRetailerQuoteResultDto>();

    @Output() menuItemClicked: EventEmitter<IRetailerQuoteVersionListMenuEvent> = new EventEmitter<IRetailerQuoteVersionListMenuEvent>();

    public readonly rows: Array<string>;
    constructor() {
        this.rows = ['objectValue', 'term', 'instalment', 'contractType'];
    }

    public onMenuItemClick(evt: IRetailerQuoteVersionListMenu, quoteId: string): void {
        this.menuItemClicked.emit({ action: evt.action, quoteId: quoteId });
    }

    public goToDetailView(evt, version: ISearchRetailerQuoteResultDto): void {
        evt.stopPropagation();
        this.goToVersionDetailView.emit(version);
    }

}
