import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILeasingQuoteOverviewVersionDto } from '@abcfinlab/api/global';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'l7-quote-versions-table',
    templateUrl: './quote-versions-table.component.html',
    styleUrls: ['./quote-versions-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteVersionsTableComponent implements OnInit {

    @Input() versions: Array<ILeasingQuoteOverviewVersionDto>;
    @Output() goToVersionDetailView: EventEmitter<ILeasingQuoteOverviewVersionDto> = new EventEmitter<ILeasingQuoteOverviewVersionDto>();

    @Output() addVendor: EventEmitter<ILeasingQuoteOverviewVersionDto> = new EventEmitter<ILeasingQuoteOverviewVersionDto>();

    private readonly _columns: Array<string>;
    private _dataSource: MatTableDataSource<ILeasingQuoteOverviewVersionDto>;

    constructor() {
        this._columns = ['version', 'last_update', 'object_name', 'total_leasing_value', 'total_terms', 'instalment', 'yearly_interest', 'contract_type', 'menu'];
    }

    public get columns(): Array<string> { return this._columns; }

    public get dataSource(): MatTableDataSource<ILeasingQuoteOverviewVersionDto> {
        return this._dataSource;
    }

    ngOnInit(): void {
        this._dataSource = new MatTableDataSource<ILeasingQuoteOverviewVersionDto>(this.versions);
    }

    public goToDetailView(evt, version: ILeasingQuoteOverviewVersionDto, column: string): void {
        if (column === 'menu') {
            return;
        }
        evt.stopPropagation();
        this.goToVersionDetailView.emit(version);
    }

    public onAddVendor(evt: ILeasingQuoteOverviewVersionDto): void {
        this.addVendor.emit(evt);
    }

}
