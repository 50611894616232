<div class="quote-summary-card-obligations" *ngIf="quoteSupportsObligations">
    <div class="obligations-headline">
        <mat-icon class="obligations-icon"
            [fontSet]="'l7'"
            [color]="'warning'"
            [fontIcon]="'Alert_Triangle'">
        </mat-icon>
        <h3>Auflage(n)</h3>
    </div>
    <p class="obligations-warning" *ngIf="!quoteHasObligations">
        Es sind keine Auflagen vorhanden!
    </p>
    <div *ngIf="quoteHasObligations" class="obligations-list"> <!-- obligations-container -->
        <div *ngFor="let obligation of obligations; index as i" class="obligations-item"> <!-- obligations-item -->
            <fieldset>
                <legend>{{ (i+1) + '. Auflage ' | translate }}</legend>
                <l7-text-block style="margin-bottom: 8px;"
                    [text]="'Anzahlung ' + obligations[i].prePaymentPercent + '% vorab'">
                </l7-text-block>
                <l7-text-block 
                    [wrap]="true" 
                    [truncate]="true"
                    [maxLines]="1"
                    [text]="obligations[i].description">
                </l7-text-block>
            </fieldset>  
        </div> <!-- /oligations-item -->
    </div> <!-- /obligations-container -->
</div>
