/**
 * LesseeService
 */
import { Injectable } from '@angular/core';
import { LeasingQuoteDTO } from '../../../models/LeasingQuoteDTO.interface';
import { Store } from '@ngxs/store';
import { FetchLesseeByUUID, SetLesseeID } from '../../../actions/Lessee.actions';
import { LesseeState } from '../../../state/Leesee.state';
import { CalculationState } from '../../../state/Calculation.state';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LesseeService {

    constructor(
        private readonly store: Store,
    ) {
    }

    /**
   *
   * @param lesseeUUID    The lessee UUID
   */
    public fetchLesseeByID(lesseeUUID: string): Observable<any> {
        return this.store.dispatch(new FetchLesseeByUUID(lesseeUUID));
    }

    public setLesseeID(lesseeUUID: string) {
        this.store.dispatch(new SetLesseeID(lesseeUUID));
    }

    public getSelectedLesseeID(): string {
        return this.store.selectSnapshot(LesseeState.selectedLesseeID);
    }

    public getLeasingQuote(): LeasingQuoteDTO {
        return this.store.selectSnapshot(CalculationState.snapShotLeasingQuote);
    }

}
