// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { SearchContactViewPresenter } from './SearchContactViewPresenter';

// #endregion

/**
 * The `SearchContactView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-search-contact-view',
    templateUrl: './SearchContactView.html',
    styleUrls: ['./SearchContactView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        SearchContactViewPresenter,
    ],
})
export class SearchContactView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: SearchContactViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `SearchContractView` class.
     *
     * @public
     */
    public constructor(presenter: SearchContactViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `SearchContractView`
     *
     * @public
     * @readonly
     */
    public get presenter(): SearchContactViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
