import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { IRetailerQuoteDetailInformationResultDto, IRetailerQuoteDto } from '@abcfinlab/api/global';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ILesseeUpdateComparisonInfo {
    oldCalculation: IRetailerQuoteDetailInformationResultDto;
    newCalculation: IRetailerQuoteDto;
}
export enum RetailerRecalculationConfirmationState {
    NewCalculation = 'NewCalculation',
    ConfirmChanges = 'ConfirmChanges',
    Cancel = 'Cancel',
}

/**
 * The RetailerRecalculationConfirmationView view.
 *
 * @public
 */
@Component({
    selector: 'l7-retailer-recalculation-confirmation-view',
    templateUrl: './RetailerRecalculationConfirmationView.html',
    styleUrls: ['./RetailerRecalculationConfirmationView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerRecalculationConfirmationView {

    private readonly _comparisonData: BehaviorSubject<ILesseeUpdateComparisonInfo>;
    protected readonly RetailerRecalculationConfirmationState = RetailerRecalculationConfirmationState;

    public constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ILesseeUpdateComparisonInfo, private readonly _dialogRef: MatDialogRef<RetailerRecalculationConfirmationView>) {
        this._comparisonData = new BehaviorSubject(dialogData);
    }

    public get comparisonData(): Observable<ILesseeUpdateComparisonInfo> {
        return this._comparisonData.asObservable();
    }

    public submitDialog(state: RetailerRecalculationConfirmationState): void {
        this._dialogRef.close(state);
    }

}
