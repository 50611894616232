// #region Imports

import {
    ContractTypesService,
    ICalculationSettingsDto,
    IContactDto,
    IContractTypeDto,
    IInhouseCalculationRequestDto,
    IInhouseCalculationResponseDto,
    ILeasingQuoteFieldToCalculateDto,
    IObjectConditionDto,
    IObjectGroupDto,
} from '@abcfinlab/api/global';
import { ControlsOf, EventHub, Globals } from '@abcfinlab/core';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { filterObjectGroupsForCalculation } from '../../../../../apps/shell/src/app/helper/calculation.helper';
import { KnownEvents } from '../../Models/KnowEvents';
import { Validators as QuoteValidators } from '../../Validators/Validators';
import { IObjectFields, OptionsFlags } from '../../Views/calculation/CalculationViewPresenter';

// #endregion

/**
 * The CalculationFormComponent view.
 *
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-calculation-form',
    templateUrl: './CalculationFormComponent.html',
    styleUrls: ['./CalculationFormComponent.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculationFormComponent implements OnInit {

    // #region Fields
    private readonly _amortizationRangeSubject: BehaviorSubject<Array<number>> = new BehaviorSubject<Array<number>>(null);
    private readonly _eventHub: EventHub;
    private readonly _contractTypesService: ContractTypesService;

    private readonly _defaultValuesForCalculation = Globals.calculation;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the calculationFormComponent class.
     *
     * @public
     */
    public constructor(eventHub: EventHub, contractTypesService: ContractTypesService) {
        this._eventHub = eventHub;
        this._contractTypesService = contractTypesService;
    }

    // #endregion

    // #region Properties
    @Input() form: FormGroup<ControlsOf<IInhouseCalculationRequestDto>>;
    @Input() objectForm: FormGroup<ControlsOf<IObjectFields>>;
    @Input() objectGroups: Array<IObjectGroupDto>;
    @Input() calculationSettings: ICalculationSettingsDto;
    @Input() leasingFactorValue: number;
    @Input() initialFormState: OptionsFlags<IInhouseCalculationRequestDto>;
    @Input() lessee: IContactDto;

    public selectedValueToCalculate: ILeasingQuoteFieldToCalculateDto = ILeasingQuoteFieldToCalculateDto.Instalment;

    public objectGroupFilterCtrl: FormControl<string> = new FormControl<string>(null);

    public filteredObjectGroups$ = new BehaviorSubject<Array<IObjectGroupDto>>(null);

    public contractTypes: Array<IContractTypeDto>;

    /**
     * Returns the amortizationRange property.
     *
     * @public
     * @readonly
     */
    public get amortizationRange(): Observable<Array<number>> {
        return this._amortizationRangeSubject.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        if (this.objectGroups) {
            this.filteredObjectGroups$.next(this.objectGroups);
        }
        this.objectGroupFilterCtrl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.filterObjectGroups());
        this._contractTypesService.getContractTypes().pipe(untilDestroyed(this))
            .subscribe(cT => this.contractTypes = cT.contractTypes);
        this.form.controls.contractType.valueChanges.pipe(untilDestroyed(this))
            .subscribe((value) => {
                this.selectContractType();
            });
    }

    public filterObjectGroups(): void {
        if (!this.objectGroups) {
            return;
        }
        // get search term
        let search = this.objectGroupFilterCtrl.value;
        if (!search) {
            this.filteredObjectGroups$.next(this.objectGroups.slice());
            return;
        }
        search = search.toString().toLowerCase();
        // do filtering
        this.filteredObjectGroups$.next(
            this.objectGroups.filter(objectGroup => filterObjectGroupsForCalculation(objectGroup, search)),
        );
    }

    public selectObjectCondition(evt: MatRadioChange): void {
        this.changeTotalTermsRange();
    }

    public selectObjectGroup(evt: MatSelectChange): void {
        this.selectedValueToCalculate = ILeasingQuoteFieldToCalculateDto.Instalment;
        this.changeTotalTermsRange();
        this.form.reset({
            ...this.initialFormState as unknown as Partial<IInhouseCalculationRequestDto>,
            ...{
                objectGroupCode: this.form.controls.objectGroupCode.getRawValue(),
                objectCondition: this.form.controls.objectCondition.getRawValue(),
            },
        }, { emitEvent: false });
        this.form.controls.contractType.enable({ emitEvent: false });
        this._eventHub.publish<{ activeControlName: string; response: IInhouseCalculationResponseDto }>(KnownEvents.CALCULATION, { activeControlName: 'objectGroupCode', response: null });
    }

    private selectContractType(): void {
        this.selectedValueToCalculate = ILeasingQuoteFieldToCalculateDto.Instalment;
        this.form.controls.quoteCalculationRequest.controls.totalLeasingValue.patchValue(null, { emitEvent: false });
        this.form.reset({
            ...this.initialFormState as unknown as Partial<IInhouseCalculationRequestDto>,
            ...{
                objectCondition: this.form.controls.objectCondition.getRawValue(),
                objectGroupCode: this.form.controls.objectGroupCode.getRawValue(),
                contractType: this.form.controls.contractType.getRawValue(),
            },
        }, { emitEvent: false });
        this.changeTotalTermsRange();
        this.form.controls.quoteCalculationRequest.controls.totalLeasingValue.enable({ emitEvent: false });
        switch (this.form.controls.contractType.getRawValue()) {
            case IContractTypeDto.Va:
            case IContractTypeDto.Mietkauf:
            case IContractTypeDto.Mkn:
                this.clearResidualValueValidators();
                break;
            case IContractTypeDto.Ta:
            case IContractTypeDto.Kfz:
                this.addResidualValueValidators();
                break;
        }
        this._eventHub.publish<{ activeControlName: string; response: IInhouseCalculationResponseDto }>(KnownEvents.CALCULATION, { activeControlName: 'contractType', response: null });
    }

    public changeValueToCalculate(evt: MouseEvent): void {
        if ((evt.currentTarget as Element).getAttribute('data-value')) {
            this.form.controls.valueToCalculate.patchValue((evt.currentTarget as Element).getAttribute('data-value') as ILeasingQuoteFieldToCalculateDto);
        }
        if (!this.form.controls.valueToCalculate.getRawValue()) {
            this.form.controls.valueToCalculate.patchValue(this.selectedValueToCalculate, { emitEvent: false });
        }
        this.selectedValueToCalculate = this.form.controls.valueToCalculate.getRawValue();
        if (!this.form.controls.quoteCalculationRequest.controls.totalLeasingValue.getRawValue()) {
            return;
        }

        switch (this.selectedValueToCalculate) {
            case ILeasingQuoteFieldToCalculateDto.Instalment:
                this.form.controls.quoteCalculationRequest.controls.instalment.disable();
                this.form.controls.quoteCalculationRequest.controls.yearlyInterestPercentage.enable({ emitEvent: false });
                this.form.controls.quoteCalculationRequest.controls.yearlyInterestPercentage.markAsTouched();
                this.enableFirstAndLastInstalment();
                break;
            case ILeasingQuoteFieldToCalculateDto.YearlyInterest:
                this.form.controls.quoteCalculationRequest.controls.yearlyInterestPercentage.disable({ emitEvent: false });
                this.form.controls.quoteCalculationRequest.controls.instalment.enable({ emitEvent: false });
                this.form.controls.quoteCalculationRequest.controls.instalment.updateValueAndValidity();
                this.enableFirstAndLastInstalment();
                break;
            case ILeasingQuoteFieldToCalculateDto.FirstInstalment:
                this.form.controls.quoteCalculationRequest.controls.firstInstalmentValue.disable();
                this.form.controls.quoteCalculationRequest.controls.firstInstalmentPercentage.disable();
                this.form.controls.quoteCalculationRequest.controls.lastInstalmentValue.enable({ emitEvent: false });
                this.form.controls.quoteCalculationRequest.controls.lastInstalmentPercentage.enable({ emitEvent: false });
                this.enableYearlyInterestAndInstalment();
                break;
            case ILeasingQuoteFieldToCalculateDto.LastInstalment:
                this.form.controls.quoteCalculationRequest.controls.lastInstalmentValue.disable();
                this.form.controls.quoteCalculationRequest.controls.lastInstalmentPercentage.disable();
                this.form.controls.quoteCalculationRequest.controls.firstInstalmentValue.enable({ emitEvent: false });
                this.form.controls.quoteCalculationRequest.controls.firstInstalmentPercentage.enable({ emitEvent: false });
                this.enableYearlyInterestAndInstalment();
                break;
        }
    }

    // #endregion
    private changeTotalTermsRange(): void {
        const preSelectedObjectGroup = this.objectGroups.find(group => group.code === this.form.controls.objectGroupCode.getRawValue());
        const minAmortizationPeriod = this.form.controls.objectCondition.getRawValue() === IObjectConditionDto.New ? preSelectedObjectGroup.amortization_period_min : this._defaultValuesForCalculation.minimumTermsForUsedObject;

        const isHirePurchase: boolean = this.form.controls.contractType.getRawValue() === IContractTypeDto.Mietkauf || this.form.controls.contractType.getRawValue() === IContractTypeDto.Mkn;
        const minPeriod = isHirePurchase ? this._defaultValuesForCalculation.minimumTermsForHirePurchase : minAmortizationPeriod;
        const maxPeriod = isHirePurchase ? this._defaultValuesForCalculation.maximumTermsForHirePurchase : preSelectedObjectGroup.amortization_period_max;
        const amortizationRange: Array<number> | null = new Array<number>();
        for (let i = minPeriod; i <= maxPeriod; i++) {
            amortizationRange.push(i);
        }

        this._amortizationRangeSubject.next(amortizationRange);
        if (this.form.controls.quoteCalculationRequest.controls.totalTerms.getRawValue() < minPeriod) {
            this.form.controls.quoteCalculationRequest.controls.totalTerms.patchValue(minPeriod);
        }
    }

    private addResidualValueValidators(): void {
        this.form.controls.quoteCalculationRequest.controls.residualValue.setValidators(QuoteValidators.allowedValuesRelatedToLeasingValue(1, this.calculationSettings.leasingQuoteCalculationProperties.minResidualValue, 'residualValue'));
        this.form.controls.quoteCalculationRequest.controls.residualValue.updateValueAndValidity();

        this.form.controls.quoteCalculationRequest.controls.residualValuePercentage.patchValue(this.calculationSettings.residualValueSettings.min * 100, { emitEvent: false });
        this.form.controls.quoteCalculationRequest.controls.residualValuePercentage.setValidators(QuoteValidators.allowedValuesRelatedToLeasingValue(1, this.calculationSettings.leasingQuoteCalculationProperties.minResidualValue, 'residualValue'));
        this.form.controls.quoteCalculationRequest.controls.residualValuePercentage.updateValueAndValidity();
    }

    private clearResidualValueValidators(): void {
        this.form.controls.quoteCalculationRequest.controls.residualValue.clearValidators();
        this.form.controls.quoteCalculationRequest.controls.residualValue.updateValueAndValidity();
        this.form.controls.quoteCalculationRequest.controls.residualValuePercentage.clearValidators();
        this.form.controls.quoteCalculationRequest.controls.residualValuePercentage.updateValueAndValidity();
    }

    private enableFirstAndLastInstalment(): void {
        this.form.controls.quoteCalculationRequest.controls.firstInstalmentValue.enable({ emitEvent: false });
        this.form.controls.quoteCalculationRequest.controls.firstInstalmentPercentage.enable({ emitEvent: false });
        this.form.controls.quoteCalculationRequest.controls.lastInstalmentValue.enable({ emitEvent: false });
        this.form.controls.quoteCalculationRequest.controls.lastInstalmentPercentage.enable({ emitEvent: false });
    }

    private enableYearlyInterestAndInstalment(): void {
        this.form.controls.quoteCalculationRequest.controls.yearlyInterestPercentage.enable({ emitEvent: false });
        this.form.controls.quoteCalculationRequest.controls.yearlyInterestPercentage.markAsTouched();
        this.form.controls.quoteCalculationRequest.controls.instalment.enable({ emitEvent: false });
        this.form.controls.quoteCalculationRequest.controls.instalment.updateValueAndValidity();
    }

}
