import { IQuoteWorkflowFirstOpenTypeDto, IWorkflowStateDto, IWorkflowStepDto } from '@abcfinlab/api/global';
import { TranslationFacade } from '@abcfinlab/core';
import { Injectable } from '@angular/core';
import { LesseeState } from '../../../../apps/shell/src/app/models/enums/LesseeState.enum';

@Injectable()
export class WorkflowStepsIndicatorsService {

    private readonly _translationFacade: TranslationFacade;

    constructor(translationFacade: TranslationFacade) {
        this._translationFacade = translationFacade;
    }

    public workflowStepTooltip(taskDone: number, workflowState: string, firstOpenType: string, workflowSteps: Array<IWorkflowStepDto>): string {
        let tooltip: string = '';
        switch (firstOpenType) {
            case IQuoteWorkflowFirstOpenTypeDto.CreditCheck: {
                if (workflowState === IWorkflowStateDto.Open) {
                    tooltip = this._translationFacade.instant('quote.retailers.quotesList.versions.tooltips.steps.creditCheck.initial');
                }
                break;
            }
            case IQuoteWorkflowFirstOpenTypeDto.Identification: {
                if (workflowSteps[0].metaInformation.creditCheckRating === LesseeState.POSITIVE) {
                    tooltip = this._translationFacade.instant('quote.retailers.quotesList.versions.tooltips.steps.creditCheck.completed-positive');
                }
                if (workflowSteps[0].metaInformation.creditCheckRating === LesseeState.PENDING) {
                    tooltip = this._translationFacade.instant('quote.retailers.quotesList.versions.tooltips.steps.creditCheck.pending');
                }
                if (workflowSteps[0].metaInformation.creditCheckRating === LesseeState.APPROVED_WITH_CONDITIONS) {
                    tooltip = this._translationFacade.instant('quote.retailers.quotesList.versions.tooltips.steps.creditCheck.completed-positive-with-obligations');
                }
                if (workflowSteps[0].metaInformation.creditCheckRating === LesseeState.APPROVED_WITH_CONDITIONS_FULFILLED) {
                    tooltip = this._translationFacade.instant('quote.retailers.quotesList.versions.tooltips.steps.creditCheck.completed-positive-with-obligations-fulfilled');
                }
                if (workflowSteps[0].metaInformation.creditCheckRating === LesseeState.NEGATIVE) {
                    tooltip = this._translationFacade.instant('quote.retailers.quotesList.versions.tooltips.steps.creditCheck.completed-negative');
                }
                break;
            }
            case IQuoteWorkflowFirstOpenTypeDto.CreateContract: {
                if (workflowState === IWorkflowStateDto.Open) {
                    tooltip = this._translationFacade.instant('quote.retailers.quotesList.versions.tooltips.steps.identification.successful');
                }
                if (workflowState === IWorkflowStateDto.Pending) {
                    tooltip = this._translationFacade.instant('quote.retailers.quotesList.versions.tooltips.steps.concludeContract.pending');
                }
                break;
            }
            case IQuoteWorkflowFirstOpenTypeDto.AllCompleted: {
                if (workflowState === IWorkflowStateDto.Pending) {
                    tooltip = this._translationFacade.instant('quote.retailers.quotesList.versions.tooltips.steps.concludeContract.pending');
                }
                break;
            }
        }

        return tooltip;
    }

}
