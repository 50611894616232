import {
    BeneficialOwnerService,
    IBeneficialOwnerDto,
    IBeneficialOwnerTypeDto, IContactDto,
} from '@abcfinlab/api/contact';
import { COUNTRIES_DE, ControlsOf, Validators as CustomValidators, FormValidator, ICountryDto, TranslationFacade, once, subscribeOnce } from '@abcfinlab/core';
import { BusyBoxService } from '@abcfinlab/ui';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { BeneficialOwnersViewPresenter } from './beneficial-owners-view.presenter';

export interface IBeneficialOwnerFormDto {
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    dateOfBirth: FormControl<string>;
    nationality: FormControl<string>;
    lesseeCrefoId: FormControl<string>;
    participationRate: FormControl<number>;
    id: FormControl<string>;
}

interface IBeneficialOwnersFormGroupDto {
    beneficialOwners: FormArray<FormGroup<IBeneficialOwnerFormDto>>;
    confirmationCheckBox: FormControl<boolean>;
}
@UntilDestroy()
@Component({
    selector: 'l7-beneficial-owners-view',
    templateUrl: './beneficial-owners-view.component.html',
    styleUrls: ['./beneficial-owners-view.component.scss'],
    providers: [BeneficialOwnersViewPresenter],
})
export class BeneficialOwnersView implements OnInit {

    private readonly _presenter: BeneficialOwnersViewPresenter;
    private readonly _hideNewBeneficialOwnersFields: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private readonly _beneficialOwnersService: BeneficialOwnerService;
    private readonly _cdr: ChangeDetectorRef;
    private readonly _formValidator: FormValidator;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _beneficialOwnersForm: FormGroup<ControlsOf<IBeneficialOwnersFormGroupDto>>;
    private _isComplementarySelected: boolean = false;
    private _selectedComplementaryIndex: number = -1;
    private _selectedManagingDirectoryIndex: number = -1;
    private _selectedBoardIndex: number = -1;
    private _isboardMemberSelected: boolean = false;
    private readonly _beneficialOwnerTypes: Array<IBeneficialOwnerTypeDto> = [IBeneficialOwnerTypeDto.Shareholder, IBeneficialOwnerTypeDto.Complementary, IBeneficialOwnerTypeDto.Board, IBeneficialOwnerTypeDto.ManagingDirector];
    public validateConfirmationCheckBoxManually: boolean = false;
    @Input() lesseeCrefoId: string = null;

    @Input() lessee: IContactDto;

    @Input() set boList(value: Array<IBeneficialOwnerDto>) {
        this.beneficialOwnersFields.clear();
        if (value) {
            value.forEach((bo, index) => {
                if (bo.beneficialOwnerType === IBeneficialOwnerTypeDto.ManagingDirector || bo.beneficialOwnerType === IBeneficialOwnerTypeDto.Board) {
                    this._isboardMemberSelected = true;
                }
                this.beneficialOwnersFields.push(this.createNewFormGroupForBeneficialOwners(bo));
            });
        }
    }

    @Output() boListUpdated: EventEmitter<number> = new EventEmitter<number>();
    @ViewChild('checkBeneficialOwners') confirmationCheckBoxRef: MatCheckbox;
    public filteredCountries$: ReplaySubject<Array<ICountryDto>> = new ReplaySubject<Array<ICountryDto>>(1);
    public activeIndex: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public readonly maxBirthdayDate: Date = new Date();
    constructor(presenter: BeneficialOwnersViewPresenter, cdr: ChangeDetectorRef, busyBoxService: BusyBoxService, translationFacade: TranslationFacade, formValidator: FormValidator, beneficialOwnersService: BeneficialOwnerService) {
        this._presenter = presenter;
        this._formValidator = formValidator;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._beneficialOwnersService = beneficialOwnersService;
        this._cdr = cdr;
        this._beneficialOwnersForm = new FormGroup<ControlsOf<IBeneficialOwnersFormGroupDto>>({
            beneficialOwners: new FormArray([]),
            confirmationCheckBox: new FormControl<boolean>(false, Validators.requiredTrue),
        });
        this.maxBirthdayDate.setFullYear(this.maxBirthdayDate.getFullYear() - 18);
        this.maxBirthdayDate.setHours(0, 0, 0, 0);
    }

    ngOnInit(): void {
        this.filteredCountries$.next(this.countries);
        this._beneficialOwnersForm.controls.beneficialOwners.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
            this.checkSelectedTypes(values);
        });
        this._beneficialOwnersForm.controls.beneficialOwners.statusChanges.pipe(
            debounceTime(100), // Adjust time as necessary
            filter(status => status === 'VALID'),
            untilDestroyed(this),
        ).subscribe(() => {
            this.boListUpdated.emit(this.beneficialOwnersList.length);
            // Emit event here
        });
    }

    public get isboardMemberSelected(): boolean {
        return this._isboardMemberSelected;
    }

    public getAvailableOptions(currentIndex: number): Array<IBeneficialOwnerTypeDto> {
        let availableOptions = this._beneficialOwnerTypes;
        if (this._isComplementarySelected && this._selectedComplementaryIndex !== currentIndex) {
            availableOptions = availableOptions.filter(type => type !== IBeneficialOwnerTypeDto.Complementary);
        }
        if ((this.beneficialOwnersFields.length > 1 || (this._selectedManagingDirectoryIndex !== -1 || this._selectedBoardIndex !== -1)) && this._selectedManagingDirectoryIndex !== currentIndex && this._selectedBoardIndex !== currentIndex) {
            availableOptions = availableOptions.filter(type => type !== IBeneficialOwnerTypeDto.ManagingDirector && type !== IBeneficialOwnerTypeDto.Board);
        }
        return availableOptions;
    }

    public updateNewBeneficialOwnerIds(crefoId: string): void {
        subscribeOnce(this._beneficialOwnersService.search({ crefoId: crefoId }), (response: Array<IBeneficialOwnerDto>) => {
            this.boList = response;
        });
    }

    public addBeneficialOwner(): void {
        this.validateBeneficialOwners();
        if (this._beneficialOwnersForm.controls.beneficialOwners.valid) {
            this.beneficialOwnersFields.disable();
        }
        this.beneficialOwnersFields.push(this.createNewFormGroupForBeneficialOwners());
    }

    public hideNewBeneficialOwners(evt: MatCheckboxChange): void {
        this._hideNewBeneficialOwnersFields.next(!evt.checked);
        this._cdr.detectChanges();
    }

    public get hideNewBeneficialOwnersFields(): Observable<boolean> {
        return this._hideNewBeneficialOwnersFields.asObservable();
    }

    public filterCountries(evt: Event, index: number): void {
        this.activeIndex.next(index);
        const arrayOfBOs = this.beneficialOwnersForm.get('beneficialOwners') as FormArray;
        // get search term
        let search = arrayOfBOs.at(index).get('countryFilter').getRawValue();
        if (!search) {
            this.filteredCountries$.next(this.countries.slice());
            return;
        }

        search = search.toString().toLowerCase();
        // do filtering
        this.filteredCountries$.next(
            this.countries.filter(country => country.name.toLowerCase().indexOf(search) > -1 || country.alpha2Code.indexOf(search) > -1),
        );
    }

    public get countries(): Array<ICountryDto> {
        return COUNTRIES_DE.sort((a, b) => {
            if (a.name === 'Deutschland') {
                return -1;
            }
            if (b.name === 'Deutschland') {
                return 1;
            }
            return a.name.localeCompare(b.name);
        });
    }

    public get beneficialOwnersForm(): FormGroup<ControlsOf<IBeneficialOwnersFormGroupDto>> {
        return this._beneficialOwnersForm;
    }

    /**
     * Returns the `beneficialOwnersFields` property.
     *
     * @public
     * @readonly
     */
    public get beneficialOwnersFields(): FormArray<FormGroup<IBeneficialOwnerFormDto>> {
        return this._beneficialOwnersForm.get('beneficialOwners') as FormArray;
    }

    public createNewFormGroupForBeneficialOwners(data?: IBeneficialOwnerDto): FormGroup {
        if (data) {
            return new FormGroup({
                firstName: new FormControl({ value: data.firstName, disabled: data.beneficialOwnerType !== 'LEGACY' }, [Validators.required, CustomValidators.noEmptyString()]),
                lastName: new FormControl({ value: data.lastName, disabled: data.beneficialOwnerType !== 'LEGACY' }, [Validators.required, CustomValidators.noEmptyString()]),
                beneficialOwnerType: new FormControl({ value: data.beneficialOwnerType === 'LEGACY' ? null : data.beneficialOwnerType, disabled: data.beneficialOwnerType !== 'LEGACY' }, Validators.required),
                countryFilter: new FormControl(null),
                nationality: new FormControl({ value: data.nationality, disabled: data.beneficialOwnerType !== 'LEGACY' }),
                city: new FormControl({ value: data.city, disabled: data.beneficialOwnerType !== 'LEGACY' }),
                dateOfBirth: new FormControl({ value: data.dateOfBirth, disabled: data.beneficialOwnerType !== 'LEGACY' }),
                participationRate: new FormControl({ value: data.participationRate, disabled: data.beneficialOwnerType !== 'LEGACY' }),
                lesseeCrefoId: new FormControl(data.lesseeCrefoId),
                id: new FormControl(data.id),
            });
        }
        return new FormGroup({
            firstName: new FormControl(null, [Validators.required, CustomValidators.noEmptyString()]),
            lastName: new FormControl(null, Validators.required),
            beneficialOwnerType: new FormControl(null, Validators.required),
            countryFilter: new FormControl(null),
            nationality: new FormControl(null),
            city: new FormControl(null),
            dateOfBirth: new FormControl(null),
            lesseeCrefoId: new FormControl(this.lesseeCrefoId),
            participationRate: new FormControl(null),
        });
    }

    public takeSignatoryAsBeneficialOwner(data): void {
        const newBoEntry: FormGroup = new FormGroup({
            firstName: new FormControl({ value: data.firstName, disabled: false }, [Validators.required, CustomValidators.noEmptyString()]),
            lastName: new FormControl({ value: data.lastName, disabled: false }, [Validators.required, CustomValidators.noEmptyString()]),
            beneficialOwnerType: new FormControl(null, Validators.required),
            countryFilter: new FormControl(null),
            nationality: new FormControl(null),
            city: new FormControl(null),
            dateOfBirth: new FormControl(null),
            lesseeCrefoId: new FormControl(this.lesseeCrefoId),
            participationRate: new FormControl(null),
        });
        this.beneficialOwnersFields.push(newBoEntry);
    }

    public deleteBeneficialOwner(id: string, index: number): void {
        if (id) {
            once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'), this._beneficialOwnersService.delete({ beneficialOwnerId: id })),
                (_) => {
                    this.beneficialOwnersFields.removeAt(index);
                    this._formValidator.validate(this._beneficialOwnersForm);
                    this.boListUpdated.emit(this.beneficialOwnersList.length);
                    this._cdr.detectChanges();
                });
        } else {
            this.beneficialOwnersFields.removeAt(index);
            this.boListUpdated.emit(this.beneficialOwnersList.length);
        }
    }

    public beneficialOwnerTypeChanged(index: number, evt: MatSelectChange) {
        const control = this.beneficialOwnersFields.controls[index].controls;
        // Validators for different cases
        const validatorMap = {
            [IBeneficialOwnerTypeDto.Shareholder]: {
                firstName: [Validators.required, CustomValidators.noEmptyString()],
                lastName: [Validators.required, CustomValidators.noEmptyString()],
                beneficialOwnerType: [Validators.required],
                dateOfBirth: [Validators.required],
                nationality: [Validators.required],
                participationRate: [Validators.required, Validators.min(25), Validators.max(100)],
            },
            [IBeneficialOwnerTypeDto.Complementary]: {
                firstName: [Validators.required, CustomValidators.noEmptyString()],
                lastName: [Validators.required, CustomValidators.noEmptyString()],
                beneficialOwnerType: [Validators.required],
                dateOfBirth: [Validators.required],
                nationality: [Validators.required],
                participationRate: [],
            },
            [IBeneficialOwnerTypeDto.Board]: {
                firstName: [Validators.required, CustomValidators.noEmptyString()],
                lastName: [Validators.required, CustomValidators.noEmptyString()],
                dateOfBirth: [Validators.required],
            },
            [IBeneficialOwnerTypeDto.ManagingDirector]: {
                firstName: [Validators.required, CustomValidators.noEmptyString()],
                lastName: [Validators.required, CustomValidators.noEmptyString()],
                dateOfBirth: [Validators.required],
            },
        };

        const selectedValidators = validatorMap[evt.value] || {};

        Object.keys(control).forEach((key) => {
            if (selectedValidators[key]) {
                control[key].setValidators(selectedValidators[key]);
                control[key].updateValueAndValidity();
            } else {
                control[key].setValidators(null);
                control[key].updateValueAndValidity();
            }
        });
        if (evt.value === IBeneficialOwnerTypeDto.Board || evt.value === IBeneficialOwnerTypeDto.ManagingDirector) {
            control.dateOfBirth.setValue(null);
            control.participationRate.setValue(null);
            control.nationality.setValue(null);
        }
    }

    public get isBeneficialOwnersListConfirmed(): boolean {
        return this.confirmationCheckBoxRef.checked;
    }

    public validateBeneficialOwners(): void {
        this._beneficialOwnersForm.markAllAsTouched();
        this._beneficialOwnersForm.updateValueAndValidity();
        this.validateConfirmationCheckBoxManually = !this.validateConfirmationCheckBoxManually;
    }

    public beneficialOwnersListSaved(): void {
        this.updateNewBeneficialOwnerIds(this.lesseeCrefoId);
        this.beneficialOwnersFields.disable();
    }

    public get beneficialOwnersList(): Array<IBeneficialOwnerDto> {
        const newBeneficialOwners = this._beneficialOwnersForm.get('beneficialOwners').getRawValue().filter(bo => bo.beneficialOwnerType !== null);
        const bOWithData = newBeneficialOwners.filter((bo) => {
            const mapped = Object.values(bo).map(value => !!value);
            const hasValues = mapped.some(value => value);
            return hasValues;
        });
        return bOWithData;
    }

    private checkSelectedTypes(values: any): void {
        this._selectedComplementaryIndex = this.beneficialOwnersList.findIndex(bo => bo.beneficialOwnerType === IBeneficialOwnerTypeDto.Complementary);
        this._selectedManagingDirectoryIndex = this.beneficialOwnersList.findIndex(bo => bo.beneficialOwnerType === IBeneficialOwnerTypeDto.Board);
        this._selectedBoardIndex = this.beneficialOwnersList.findIndex(bo => bo.beneficialOwnerType === IBeneficialOwnerTypeDto.ManagingDirector);

        if (this._selectedManagingDirectoryIndex !== -1 || this._selectedBoardIndex !== -1) {
            this._isboardMemberSelected = true;
        } else {
            this._isboardMemberSelected = false;
        }

        if (this._selectedComplementaryIndex !== -1) {
            this._isComplementarySelected = true;
        } else {
            this._isComplementarySelected = false;
        }
    }

}
