@charset "UTF-8";

/* apps/shell/src/app/private/pages/calculation/calculation.component.scss */
.hidden-mobile {
  display: none;
}
@media screen and (min-width: 600px) {
  .hidden-mobile {
    display: unset;
  }
}
:host {
}
:host .page-container {
  max-width: unset;
  width: unset;
}
:host .page-container h2 {
  font-weight: bold;
  margin-top: 25px;
}
:host .page-container h4 {
  color: #19485a;
}
@media screen and (min-width: 1024px) {
  :host .page-container {
    margin-top: 20px;
  }
}
:host .checkbox-label {
  min-width: 200px;
  display: inline-block;
}
:host .input-container.input-object-group {
  grid-column: span 2;
}
@media screen and (min-width: 600px) {
  :host .input-container.input-object-group {
    grid-column: span 1;
  }
}
:host .input-container.input-contract-type {
  grid-column: span 2;
}
@media screen and (min-width: 600px) {
  :host .input-container.input-contract-type {
    grid-column: span 1;
  }
}
@media screen and (min-width: 1024px) {
  :host .header {
    padding: 0 20px;
  }
}
:host .form-basic {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
:host .form-provision {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 10px;
  grid-area: formprovision;
  grid-row-start: 5;
  grid-row-end: 6;
}
:host .form-conditions {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin: 10px 0;
}
@media screen and (min-width: 1024px) {
  :host .form-conditions {
    grid-template-columns: repeat(5, 1fr);
  }
}
:host .form-leasing-object {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  width: 100%;
  margin: 10px 0;
}
:host ::ng-deep l7-slb-code-form,
:host ::ng-deep l7-calculator-fee-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0;
  width: 100%;
  margin: 0;
}
:host ::ng-deep l7-function-resolve hr,
:host ::ng-deep l7-calculator-fee-form hr {
  top: 18px;
}
:host ::ng-deep .right .input-container {
  margin-bottom: 10px;
}
:host ::ng-deep .input-container {
  margin-bottom: 25px;
}
:host ::ng-deep .input-container hr {
  top: 10px;
}
:host ::ng-deep .bottom hr {
  margin: 15px 0;
}
:host ::ng-deep l7-function-resolve hr,
:host ::ng-deep l7-calculator-used-condition-form hr,
:host ::ng-deep l7-calculator-fee-form hr,
:host ::ng-deep .bottom hr,
:host ::ng-deep .input-container hr {
  position: relative;
  height: 1px;
  border-width: 0;
  background-color: #eee;
  color: #eee;
}
:host ::ng-deep .form.form-insurance .input-container,
:host ::ng-deep .form.form-slb-code .input-container {
  grid-column: span 2;
}
:host ::ng-deep .form.form-calculation {
  grid-gap: 10px;
}
:host ::ng-deep .form.form-calculation,
:host ::ng-deep .form.form-insurance,
:host ::ng-deep .form.form-slb-code {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media screen and (min-width: 1024px) {
  :host ::ng-deep .form.form-calculation,
  :host ::ng-deep .form.form-insurance,
  :host ::ng-deep .form.form-slb-code {
    padding: unset;
  }
}
:host ::ng-deep .form.form-calculation .checkbox-label,
:host ::ng-deep .form.form-insurance .checkbox-label,
:host ::ng-deep .form.form-slb-code .checkbox-label {
  font-size: 17px;
  font-weight: bold;
}
:host ::ng-deep .form.form-calculation .input-container,
:host ::ng-deep .form.form-insurance .input-container,
:host ::ng-deep .form.form-slb-code .input-container {
  display: flex;
  flex-direction: column;
}
:host ::ng-deep .form.form-calculation .input-container.hire-purchase,
:host ::ng-deep .form.form-insurance .input-container.hire-purchase,
:host ::ng-deep .form.form-slb-code .input-container.hire-purchase {
  grid-column: span 2;
}
:host ::ng-deep .form.form-calculation .input-container.hire-purchase .left mat-form-field,
:host ::ng-deep .form.form-insurance .input-container.hire-purchase .left mat-form-field,
:host ::ng-deep .form.form-slb-code .input-container.hire-purchase .left mat-form-field {
  padding-left: 10px;
}
:host ::ng-deep .form.form-calculation .input-container.hire-purchase .left mat-form-field #input_hire_purchase_terms,
:host ::ng-deep .form.form-insurance .input-container.hire-purchase .left mat-form-field #input_hire_purchase_terms,
:host ::ng-deep .form.form-slb-code .input-container.hire-purchase .left mat-form-field #input_hire_purchase_terms {
  width: 100px;
}
:host ::ng-deep .form.form-calculation .input-container .left,
:host ::ng-deep .form.form-calculation .input-container .center,
:host ::ng-deep .form.form-calculation .input-container .right,
:host ::ng-deep .form.form-insurance .input-container .left,
:host ::ng-deep .form.form-insurance .input-container .center,
:host ::ng-deep .form.form-insurance .input-container .right,
:host ::ng-deep .form.form-slb-code .input-container .left,
:host ::ng-deep .form.form-slb-code .input-container .center,
:host ::ng-deep .form.form-slb-code .input-container .right {
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}
@media screen and (min-width: 1024px) {
  :host ::ng-deep .form.form-calculation .input-container .left,
  :host ::ng-deep .form.form-insurance .input-container .left,
  :host ::ng-deep .form.form-slb-code .input-container .left {
    width: 14% !important;
  }
  :host ::ng-deep .form.form-calculation .input-container .left.select-insurance,
  :host ::ng-deep .form.form-insurance .input-container .left.select-insurance,
  :host ::ng-deep .form.form-slb-code .input-container .left.select-insurance {
    width: 49% !important;
  }
}
@media screen and (min-width: 1024px) {
  :host ::ng-deep .form.form-calculation .input-container .center,
  :host ::ng-deep .form.form-insurance .input-container .center,
  :host ::ng-deep .form.form-slb-code .input-container .center {
    width: 33% !important;
  }
}
@media screen and (min-width: 1024px) {
  :host ::ng-deep .form.form-calculation .input-container .right,
  :host ::ng-deep .form.form-calculation .input-container .total-claim,
  :host ::ng-deep .form.form-calculation .input-container .total-claim-insurance,
  :host ::ng-deep .form.form-insurance .input-container .right,
  :host ::ng-deep .form.form-insurance .input-container .total-claim,
  :host ::ng-deep .form.form-insurance .input-container .total-claim-insurance,
  :host ::ng-deep .form.form-slb-code .input-container .right,
  :host ::ng-deep .form.form-slb-code .input-container .total-claim,
  :host ::ng-deep .form.form-slb-code .input-container .total-claim-insurance {
    width: 49% !important;
  }
}
@media screen and (min-width: 1024px) {
  :host ::ng-deep .form.form-calculation .input-container,
  :host ::ng-deep .form.form-insurance .input-container,
  :host ::ng-deep .form.form-slb-code .input-container {
    flex-direction: row;
    justify-content: space-between;
  }
}
:host ::ng-deep .form.form-calculation .input-container .slide-toggle,
:host ::ng-deep .form.form-insurance .input-container .slide-toggle,
:host ::ng-deep .form.form-slb-code .input-container .slide-toggle {
  padding: 18px 0 0 0;
}
@media screen and (min-width: 1024px) {
  :host ::ng-deep .form.form-calculation .input-container .slide-toggle,
  :host ::ng-deep .form.form-insurance .input-container .slide-toggle,
  :host ::ng-deep .form.form-slb-code .input-container .slide-toggle {
    width: 49%;
  }
}
@media screen and (min-width: 1024px) {
  :host ::ng-deep .form.form-calculation .input-container .input,
  :host ::ng-deep .form.form-insurance .input-container .input,
  :host ::ng-deep .form.form-slb-code .input-container .input {
    width: 49%;
  }
}
:host ::ng-deep mat-form-field {
  position: relative;
  height: 54px;
  width: 100%;
}
:host ::ng-deep mat-form-field.smaller {
  max-width: 200px;
}
:host ::ng-deep mat-form-field.selected-to-calculate {
  background: #3891a6 !important;
  color: #fff !important;
}
:host ::ng-deep mat-form-field.fixed-for-calculation {
  background: #3891a6;
  color: #fff;
}
:host ::ng-deep mat-form-field input.fixed-for-calculation {
  color: white !important;
}
:host ::ng-deep mat-form-field .Alert_Circle {
  color: #ffa800;
}
.calculator-screen {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas: "header header" "body body" "footer footer";
}
.calculator-screen .header {
  display: grid;
  grid-area: header;
  grid-template-columns: 100% 50%;
  grid-template-rows: auto;
  grid-template-areas: "left" "right";
}
@media screen and (min-width: 600px) {
  .calculator-screen .header {
    grid-template-columns: 50% 50%;
    grid-template-areas: "left right";
    padding: 10px 20px 0 20px;
  }
}
.calculator-screen .header .left {
  grid-area: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (min-width: 600px) {
  .calculator-screen .header .left {
    padding-right: 20px;
  }
}
.calculator-screen .header .right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  grid-area: right;
}
@media screen and (min-width: 600px) {
  .calculator-screen .header .right {
    padding-left: 20px;
    border-left: 1px solid #eee;
  }
}
.calculator-screen .body {
  grid-area: body;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas: "left" "right" "bottom";
  position: relative;
}
@media screen and (min-width: 600px) {
  .calculator-screen .body {
    grid-template-columns: 50% 50%;
    grid-template-areas: "left right" "bottom bottom";
  }
}
.calculator-screen .body .left {
  display: grid;
  grid-area: left;
  grid-gap: 10px;
}
@media screen and (min-width: 600px) {
  .calculator-screen .body .left {
    padding: 10px 20px 0 20px;
  }
}
.calculator-screen .body .right {
  display: grid;
  grid-area: right;
  grid-template-areas: "formusedconition" "forminsurance" "formslbcode" "formprovision";
}
@media screen and (min-width: 1024px) {
  .calculator-screen .body .right {
    padding: 10px 20px 0 20px;
    border-left: 1px solid #eee;
  }
}
.calculator-screen .body .bottom {
  grid-area: bottom;
}
@media screen and (min-width: 600px) {
  .calculator-screen .body .bottom {
    padding: 0 20px;
  }
}
.calculator-screen .footer {
  grid-area: footer;
}
.field-hidden {
  transition: all 500ms ease-in-out;
  opacity: 0;
}
/*# sourceMappingURL=calculation.component.css.map */
