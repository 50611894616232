import { ContactService, IContactDto, IContactTypeDto, IIdTypeDto } from '@abcfinlab/api/contact';
import { FeatureManager, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService } from '@abcfinlab/ui';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { SetLessee } from '../../../../actions/Lessee.actions';
import { ContactDTO } from '../../../../models/ContactDTO.interface';
import {
    GenericDialogComponent,
    GenericDialogData,
} from '../../../../shared/modals/generic-dialog/generic-dialog.component';
import { ErrorHandlerService } from '../../../../shared/services/errorHandler/error-handler.service';
import { ContactState } from '../../../../state/Contact.state';
import { DialogService } from '../../../services/dialog/dialog.service';

@UntilDestroy()
@Component({
    selector: 'l7-page-choose-lessee',
    templateUrl: './page-choose-lessee.component.html',
    styleUrls: ['./page-choose-lessee.component.scss'],
})
export class PageChooseLesseeComponent implements OnInit {

    // #region Fields

    private readonly _router: Router;
    private readonly _store: Store;
    private readonly _dialogService: DialogService;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _featureManager: FeatureManager;
    private readonly _contactService: ContactService;

    // #endregion

    // #region Ctor

    constructor(router: Router, store: Store,
        dialogService: DialogService,
        busyBoxService: BusyBoxService,
        featureManager: FeatureManager,
        contactService: ContactService,
        private readonly translationFacade: TranslationFacade,
        private readonly errorHandler: ErrorHandlerService,
        private readonly activatedRoute: ActivatedRoute,
    ) {
        this._router = router;
        this._store = store;
        this._dialogService = dialogService;
        this._busyBoxService = busyBoxService;
        this._featureManager = featureManager;
        this._contactService = contactService;
        this.$searchResult = this._store.select(ContactState.getContactListFormSearchResult) as Observable<Array<ContactDTO>>;
    }

    ngOnInit(): void {
        this.errorHandler.errorDialogCloseSubject.pipe(
            filter(x => x === 'contact_blocked_nav'),
            untilDestroyed(this),
        ).subscribe(() => {

        });
    }

    // #endregion

    // #region Properties

    public $searchResult: Observable<Array<ContactDTO>>;

    // #endregion

    // #region Methods

    public onSelectionChange(item: ContactDTO): void {
        if (!item.allowed_as_lessee) {
            return;
        }
        const _lessee = {
            ...item,
            ...{ postcode: item.postal_code },
        };
        this._busyBoxService.show(null, null, this._contactService.checkContactNavStateByType({ id: _lessee.crefo_id, contactType: 'lessee', idType: IIdTypeDto.Crefo })).pipe(
            switchMap(x => x.contactNo.length ? of(true) : of(false)),
            untilDestroyed(this),
        ).subscribe((x) => {
            if (x) {
                this._busyBoxService.show(null, null, this._contactService.checkIfNewContactNeeded({ id: _lessee.crefo_id, idType: IIdTypeDto.Crefo }), { id: 'checkNewContact' })
                    .pipe(untilDestroyed(this))
                    .subscribe((res: IContactDto) => {
                        if (res.new_contact_needed) {
                            const contactDto = res;
                            const name = contactDto.name;
                            const address = this.formatAddress(contactDto);
                            const data: GenericDialogData = {
                                id: 'dialog_contact_alert_changed_address',
                                image: 'assets/images/icon-create-contract.svg',
                                title: 'Abweichende Adresse',
                                body: this.translationFacade.instant('dialogs.new_contact_address_lessee', {
                                    param1: name,
                                    param2: address,
                                }),
                                negativeText: 'global.cancel',
                                positiveText: 'global.further',
                            };
                            this._dialogService.openDialog(GenericDialogComponent, {}, data).afterClosed().subscribe((xx) => {
                                if (xx) {
                                    // TODO remove store dispatch after new calculation on PROD
                                    this._store.dispatch(new SetLessee(res as unknown as ContactDTO)).subscribe(() => {
                                        this.navigatetoCalculation(res, true);
                                    });
                                } else {
                                    // item.source.deselectAll();
                                }
                            });
                        } else {
                            // TODO remove store dispatch after new calculation on PROD
                            this._store.dispatch(new SetLessee(_lessee)).subscribe(() => {
                                this.navigatetoCalculation(res);
                            });
                        }
                    });
            }
        }, (error) => {
            // customer is protected
            if (error instanceof HttpErrorResponse && error.status === 409 && error.error.error === 'contact_customer_protected') {
                const data: GenericDialogData = {
                    id: 'dialog_contact_customer_protected',
                    image: 'assets/images/image-failure.svg',
                    title: 'Bestehender Kundenschutz',
                    body: 'dialogs.contact_customer_protected',
                    positiveText: 'global.close',
                };
                this._dialogService.openDialog(GenericDialogComponent, undefined, data);
            }

            if (error instanceof HttpErrorResponse && error.status === 409 && error.error.error === 'contact_customer_reserved') {
                const data: GenericDialogData = {
                    id: 'dialog_contact_customer_reserved',
                    image: 'assets/images/image-failure.svg',
                    title: 'Bestehende Reservierung für Kundenschutz',
                    body: 'dialogs.contact_customer_reserved',
                    negativeText: 'global.cancel',
                    positiveText: 'global.do_calculation',
                };
                this._dialogService.openDialog(GenericDialogComponent, undefined, data)
                    .afterClosed().subscribe((x) => {
                        if (x) {
                            this._busyBoxService.show(null, null, this._contactService.checkIfNewContactNeeded({ id: _lessee.crefo_id, idType: IIdTypeDto.Crefo }), { id: 'checkNewContact' })
                                .pipe(untilDestroyed(this))
                                .subscribe((res: IContactDto) => {
                                    if (res.new_contact_needed) {
                                        const contactDto = res;
                                        const name = contactDto.name;
                                        const address = this.formatAddress(contactDto);
                                        const _data: GenericDialogData = {
                                            id: 'dialog_contact_alert_changed_address',
                                            image: 'assets/images/icon-create-contract.svg',
                                            title: 'Abweichende Adresse',
                                            body: this.translationFacade.instant('dialogs.new_contact_address_lessee', {
                                                param1: name,
                                                param2: address,
                                            }),
                                            negativeText: 'global.cancel',
                                            positiveText: 'global.further',
                                        };
                                        this._dialogService
                                            .openDialog(GenericDialogComponent, {}, _data).afterClosed().subscribe((xx) => {
                                                if (xx) {
                                                    // TODO remove store dispatch after new calculation on PROD
                                                    this._store.dispatch(new SetLessee(res as unknown as ContactDTO)).subscribe(() => {
                                                        this.navigatetoCalculation(res, true);
                                                    });
                                                }
                                            });
                                    } else {
                                        // TODO remove store dispatch after new calculation on PROD
                                        this._store.dispatch(new SetLessee(_lessee)).subscribe(() => {
                                            this.navigatetoCalculation(res as unknown as IContactDto);
                                        });
                                    }
                                });
                        } else {
                            // item.source.deselectAll();
                        }
                    });
            }
        });
    }

    public formatAddress(contact: IContactDto | ContactDTO): string {
        let address = '';
        if (contact.street) {
            address += contact.street;
        }

        if (contact.house_number) {
            address += ` ${contact.house_number}`;
        }

        if ('postcode' in contact && contact.postcode) {
            address += `, ${contact.postcode}`;
        } else if ('postal_code' in contact && contact.postal_code) {
            address += `, ${contact.postal_code}`;
        }

        if (contact.city) {
            address += ` ${contact.city}`;
        }

        if ('contact_number' in contact && contact.contact_number !== null) {
            address += ` | abcfinance Kontaktnr:${contact.contact_number}`;
        }

        return address;
    }

    private navigatetoCalculation(lessee: IContactDto, createNewContact: boolean = false): void {
        let routePath = '../calculation';
        if (this._featureManager.active('newCalculation')) {
            routePath = `../calculation/${lessee.crefo_id}`;
        }
        if (createNewContact) {
            this._contactService.create({ contactType: IContactTypeDto.Lessee, body: lessee }).pipe(untilDestroyed(this))
                .subscribe((_) => {
                    void this._router.navigate([routePath], {
                        relativeTo: this.activatedRoute,
                    });
                });
            return;
        }

        void this._router.navigate([routePath], {
            relativeTo: this.activatedRoute,
        });
    }

}
