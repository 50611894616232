// #region Imports

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import {
    RetailerQuoteViewPresenter,
} from '../retailer-quote/RetailerQuoteViewPresenter';
import { RetailerCalculationView } from '../retailer-calculation/RetailerCalculationView';
import { Location } from '@angular/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatStepper } from '@angular/material/stepper';
import { IWorkflowStepStatusDto } from '@abcfinlab/api/global';
import { BehaviorSubject } from 'rxjs';

// #endregion

/**
 * The `RetailerQuoteView` view.
 *
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-retailer-create-quote-view',
    templateUrl: './RetailerQuoteView.html',
    styleUrls: ['./RetailerQuoteView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        RetailerQuoteViewPresenter,
    ],
})
export class RetailerQuoteView implements OnInit, OnDestroy, AfterViewInit {

    // #region Fields

    @ViewChild('calculationStepper') private readonly _calculationStepper: RetailerCalculationView;

    @ViewChild('stepper') private readonly _stepper: MatStepper;

    private readonly _presenter: RetailerQuoteViewPresenter;

    private readonly _location: Location;

    private readonly _cdr: ChangeDetectorRef;

    private readonly _creditCheckStatus: BehaviorSubject<IWorkflowStepStatusDto> = new BehaviorSubject<IWorkflowStepStatusDto>(null);

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `RetailerQuoteView` class.
     *
     * @public
     */
    public constructor(presenter: RetailerQuoteViewPresenter, location: Location, cdr: ChangeDetectorRef) {
        this._presenter = presenter;
        this._location = location;
        this._cdr = cdr;
    }

    // #endregion

    // #region Properties

    public ngAfterViewInit(): void {
        this._cdr.detectChanges();
    }

    /**
     * Returns the presenter of the `RetailerQuoteView`
     *
     * @public
     * @readonly
     */
    public get presenter(): RetailerQuoteViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    public isCalculationOnFirstStep(): boolean {
        return this._calculationStepper?.stepperCreateQuote.selectedIndex === 0;
    }

    /**
     * @internal
     */
    public goBack(): void {
        if (this._calculationStepper.stepperCreateQuote.selectedIndex === 0) {
            this._location.historyGo(-1);
        } else {
            this._calculationStepper.stepperCreateQuote.previous();
        }
    }

    /**
     * @internal
     */
    public setCreditCheckStatus(status: IWorkflowStepStatusDto): void {
        this._creditCheckStatus.next(status);
    }

    // #endregion

}
