import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'l7-boni-check-list-item',
    templateUrl: './boni-check-list-item.component.html',
    styleUrls: ['./boni-check-list-item.component.scss'],
})
export class BoniCheckListItemComponent {

    @Input() quoteTask: any = null;
    @Input() disabled: boolean = false;
    @Input() pending: boolean = false;
    @Input() menuItems: any = [];
    @Output() clicked = new EventEmitter<any>();
    @Output() openModal = new EventEmitter<any>();
    @Output() menuItemClicked = new EventEmitter<any>();

    // GETTERS

    public get isPending(): boolean {
        return this.pending || this.creditCheckRating === 'PENDING';
    }

    public get isReadOnly(): boolean {
        return this.quoteTask.status === 'COMPLETED' || this.quoteTask.status === 'PENDING' || this.isPending;
    }

    public get isDisabled(): boolean {
        return this.disabled;
    }

    public get status(): string {
        return this.quoteTask.status;
    }

    public get creditCheckRating(): string {
        return this.quoteTask.metaInformation.creditCheckRating;
    }

    public get renderMenu(): boolean {
        return !!this.menuItems.length && this.menuItems.length > 0;
    }

    /**
   * Return additional text / attributes for *completed* states for translation purpose
   */
    public get translationAttributes(): Record<string, any> {
        const obligationMetadata = this.quoteTask?.metaInformation?.creditCheckObligations || null;
        // @todo: LEASA-XXXX: Omit last comma?!
        const concatObligationDescriptions = (obligations: any) => {
            if (!obligations) {
                return;
            }
            let text = '';
            obligations.forEach((obligation) => {
                text += ` ${obligation.description},`;
            });
            return text;
        };

        return {
            obligationText: concatObligationDescriptions(obligationMetadata?.obligations),
            creditcheckPrepayment: obligationMetadata?.obligation_in_percent,
        };
    }

    // METHODS

    /**
   * EventEmitter if element is clicked
   */
    public onClick(ev: Event): void {
        if (this.isDisabled || this.isReadOnly) {
            return;
        }
        this.clicked.emit({ payload: this.quoteTask });
    }

    /**
   * EventEmitter if a menu item is clicked
   */
    public onMenuItemClick(action: string): void {
        this.menuItemClicked.emit({ action, id: this.quoteTask.id });
    }

    /**
   * EventEmitter if the *more Information* link is clicked on *PENDING* state
   */
    public onOpenInformationModal(ev: Event) {
        this.openModal.emit();
    }

}
