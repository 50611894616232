// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { SelectLesseeViewPresenter } from './SelectLesseeViewPresenter';

// #endregion

/**
 * The `SelectLesseeView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-select-lessee-view',
    templateUrl: './SelectLesseeView.html',
    styleUrls: ['./SelectLesseeView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        SelectLesseeViewPresenter,
    ],
})
export class SelectLesseeView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: SelectLesseeViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `SelectLesseeView` class.
     *
     * @public
     */
    public constructor(presenter: SelectLesseeViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `SelectLesseeView`
     *
     * @public
     * @readonly
     */
    public get presenter(): SelectLesseeViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
