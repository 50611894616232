import { Component, OnInit } from '@angular/core';
import { RetailerUpdateLesseeOverviewPresenter } from './retailer-update-lessee-overview-presenter';
import { Location } from '@angular/common';
@Component({
    selector: 'retailer-update-lessee-overview',
    templateUrl: './retailer-update-lessee-overview.component.html',
    styleUrls: ['./retailer-update-lessee-overview.component.scss'],
    providers: [RetailerUpdateLesseeOverviewPresenter],
})
export class RetailerUpdateLesseeOverviewComponent implements OnInit {

    private readonly _presenter: RetailerUpdateLesseeOverviewPresenter;
    private readonly _location: Location;
    constructor(presenter: RetailerUpdateLesseeOverviewPresenter, location: Location) {
        this._presenter = presenter;
        this._location = location;
    }

    /**
     * Returns the presenter of the `RetailerLesseesOverview`
     *
     * @public
     * @readonly
     */
    public get presenter(): RetailerUpdateLesseeOverviewPresenter {
        return this._presenter;
    }

    ngOnInit(): void {
        this._presenter.initialize();
    }

    public goBack(): void {
        this._location.historyGo(-1);
    }

}
