// #region Imports

import { Pipe, PipeTransform } from '@angular/core';
import { ILesseeOverviewDto } from '@abcfinlab/api/contact';

// #endregion

/**
 * @public
 */
@Pipe({
    name: 'lesseeFilterBy',
})
export class LesseeFilterByPipe implements PipeTransform {

    // #region Methods

    /**
     * @public
     */
    public transform(lessees: Array<ILesseeOverviewDto>, term: string): Array<ILesseeOverviewDto> {
        if (!lessees) {
            return [];
        }

        if (!term) {
            return lessees;
        }

        term = term.toLowerCase();

        return lessees.filter(item => item.name.toLowerCase().includes(term)
            || item.street.toLowerCase().includes(term)
            || item.city.toLowerCase().includes(term)
            || item.postcode.toLowerCase().includes(term));
    }

    // #endregion

}
