// #region Imports

import { Component, OnInit } from '@angular/core';
import { RetailerLesseesOverviewPresenter } from './retailer-lessees-Overview-presenter';

// #endregion

@Component({
    selector: 'l7-retailer-lessees-overview',
    templateUrl: './retailer-lessees-overview.component.html',
    styleUrls: ['./retailer-lessees-overview.component.scss'],
    providers: [RetailerLesseesOverviewPresenter],
})
export class RetailerLesseesOverviewComponent implements OnInit {

    // #region Fields

    private readonly _presenter: RetailerLesseesOverviewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `retailerlesseesoverviewcomponent` class.
     *
     * @public
     */
    public constructor(presenter: RetailerLesseesOverviewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the presenter of the `RetailerLesseesOverview`
     *
     * @public
     * @readonly
     */
    public get presenter(): RetailerLesseesOverviewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    public ngOnInit(): void {
        this._presenter.initialize();
    }

    // #endregion

}
